import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button, Grid } from '@mui/material'
import { addEntry } from '../services/entries.service.js'

const InputBoard = ( props ) => {

    let handleClick = (side) => {
        let id = uuidv4().toString();
        let d = Date.now();
        const date = new Date(d);
        let dateTime = date.toISOString();
        addEntry(side, id, dateTime);
        props.updateEntries(true)
    }

    return(
        <Grid 
            container
            spacing={2}
            style={{ minWidth: '250px' }}
            >
            <Grid item xs={6}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 0}}
                    onClick={() => handleClick('L')}
                >L</Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 0 }}
                    onClick={() => handleClick('R')}
                >R</Button>
            </Grid>
            {/* SCHOPPEN Button ready for implementation */}
            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 0, mb: 1 }}
                    onClick={() => handleClick('S')}
                >S</Button>
            </Grid>
        </Grid>
    )
}

export default InputBoard