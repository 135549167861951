import React, { useState, useEffect } from 'react';
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import ChildCareIcon from '@mui/icons-material/ChildCare';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import InsightsIcon from '@mui/icons-material/Insights';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MenuIcon from '@mui/icons-material/Menu';

const NavBarBottom = ({currentPageChanger, ...rest}) => {
    const [value, setValue] = useState(1);

    useEffect(() => {
        
    },[]);

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction 
                    label="Menu" 
                    icon={<MenuIcon />} 
                />
                <BottomNavigationAction 
                    label="Stillen" 
                    icon={<ChildCareIcon />} 
                    onClick={() => currentPageChanger('stillen')}
                />
                <BottomNavigationAction 
                    label="History" 
                    icon={<FormatListBulletedIcon />} 
                    onClick={() => currentPageChanger('history')}
                />
            </BottomNavigation>
        </Paper>
    );
}

export default NavBarBottom;
