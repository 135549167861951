import React, { useState, useEffect, createContext } from 'react';
import './App.css';
import ListView from './components/ListView.js';
import DashboardStillen from './components/DashboardStillen';
import InputBoard from './components/InputBoard.js';
import NavBarBottom from './components/NavBarBottom.js';
import { getEntries } from './services/entries.service.js';
import { Typography, Grid, Divider } from '@mui/material';
import { Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import moment from 'moment';
// import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';

export const ThemeContext = createContext(null);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
// const lightTheme = createTheme({
//   palette: {
//     mode: 'light',
//   },
// });

const App = () => {
  const [entries, setEntries] = useState([]);
  const [updateEntries, setUpdateEntries] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [isLogedIn, setIsLogedIn] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };
  // const [theme, setTheme] = useState("dark");
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // const theme = React.useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         mode: prefersDarkMode ? 'dark' : 'light',
  //       },
  //     }),
  //   [prefersDarkMode],
  // );

  const compare = (a, b) => {
    if (a.datetime < b.datetime) { return 1 };
    if (a.datetime > b.datetime) { return -1 };
    return 0;
  }

  const updateList = async () => {
    // setIsLoading(true);
    const response = await getEntries();
    setEntries(response.sort(compare));
    setUpdateEntries(false);
    // setIsLoading(false);
  }

  useEffect(() => {
    // updateEntries ? console.log("updateEntries: ", updateEntries) : updateList();
    updateList();
  },[updateEntries])

  let mainContent;
  if (currentPage === "history") {
    mainContent = <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh', maxWidth: '100vw'}}
      className="App"
    >
      <Grid item>
        <Typography variant="h3">Mein Stillbuch</Typography>
        <Divider />
      </Grid>

      <Grid item>
        <InputBoard
          updateEntries={updateEntries => setUpdateEntries(updateEntries)}
        />
      </Grid>

      <Grid item>
        <ListView
          entries={entries}
          updateEntries={updateEntries => setUpdateEntries(updateEntries)}
        />
      </Grid>
    </Grid>;
  } else if (currentPage === "stillen") {
    mainContent = <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh' }}
      className="App"
    >
      <Grid item height="10vh">
        <Typography variant="h4">Today</Typography>
        <Divider />
      </Grid>
      <Grid item height="40vh">
        <DashboardStillen
          entries={entries}
          updateEntries={updateEntries => setUpdateEntries(updateEntries)}
        />
      </Grid>
      <Grid item 
        height="auto" 
        margin="auto">
        <InputBoard
          updateEntries={updateEntries => setUpdateEntries(updateEntries)}
        />
      </Grid>
    </Grid>;
  } else if (currentPage === "profile") {
    let loginText;
    isLogedIn ? loginText = "you're loged in!" : loginText = "please log in first.";

    mainContent = <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh' }}
      className="App"
    >
      <Grid item>
        <Typography variant="h3">Profile</Typography>
        <Divider />
      </Grid>
      <Grid item>
        <Typography>{loginText}</Typography>
        {/* <ProfilePage /> */}
      </Grid>
    </Grid>;
  }
  else {
    mainContent = <Typography>nothing to show for "{currentPage}"</Typography>
  }
  useEffect(() => {
    setCurrentPage('stillen');
    updateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {mainContent}
      <NavBarBottom 
        currentPageChanger={setCurrentPage} />
    </ThemeProvider>
  )

}

export default App
