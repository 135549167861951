// URLs
const apiUrl = process.env.REACT_APP_API_URL
const entryURL = apiUrl+'/entry'
const entriesURL = apiUrl+'/entries'

export async function addEntry(side, id, dateTime){
    let entry = {
        "id": id,
        "side": side,
        "datetime": dateTime,
        }
    let method = 'POST'
    return await fetch(entryURL, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(entry),
    }).then((response) => response.json()).then(json => console.log("addEntry: ", json))
}

export async function getEntries(){
    const entries = await fetch(entriesURL).then((response) => response.json()).then((json) => json.items)
    if (!Array.isArray(entries)) {
        return []
    }
    return entries
}

// get single Entry by ID -> needs to be developed in backend first.
// export async function getEntriesById(entryId){
//     const entries = await fetch(entriesURL+"/"+entryId).then((response) => response.json()).then((json) => json.items)
//     if (!Array.isArray(entries)) {
//         return []
//     }
//     return entries
// }

export async function patchEntry(id, side, dateTime){
    let entry = {
        "id": id,
        "side": side,
        "datetime": dateTime,
        }
    let method = 'PATCH'
    return await fetch(entriesURL+"/"+id, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(entry),
    }).then((response) => response.json()).then(json => console.log("patchEntry: ", json))
}

export async function deleteEntry(id){
    let method = 'DELETE'
    return await fetch(entriesURL+"/"+id, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((response) => response.json()).then(json => console.log("deleteEntry: ", json))
}